@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.borderless-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  appearance: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[data-floating-ui-portal] > div {
  z-index: 9999 !important;
}

.primary-button {
  background-color: #5131d7;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  gap: 0.5rem;
  height: 40px;
  width: fit-content;
}

.secondary-button {
  background-color: rgb(243, 244, 246);
  color: #5131d7;
  font-weight: 700;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  gap: 0.5rem;
  height: 40px;
}

.secondary-button:hover {
  background-color: #efeafe;
}
.MuiButtonBase-root {
  font-size: 14px !important;
}
.MuiAutocomplete-inputRoot:focus-visible {
  outline: none !important;
}

input[type="radio"] {
  accent-color: #5131d7;
}

input[type="checkbox"] {
  accent-color: #5131d7;
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: 1px solid #000 !important;
  border-radius: 2px;
}

input {
  font-size: 14px;
  height: 50px;
  border-radius: 8px;
}

textarea {
  border-radius: 8px;
}

input::placeholder {
  font-size: 14px;
}

textarea::placeholder {
  font-size: 14px;
}

.text-sm {
  font-size: 14px !important;
}

input:focus-visible,
input:focus,
textarea:focus-visible {
  outline: none;
}

textarea:focus-visible {
  border: 1px solid #5131d7;
}

input:focus-visible {
  border: 1px solid #5131d7;
}

.MuiAutocomplete-inputFocused {
  border: none !important;
}

.MuiChip-label {
  font-size: 14px;
}

.table-chip > .MuiChip-label {
  font-size: 12px;
}

.MuiAutocomplete-option {
  font-size: 14px;
  padding: 4px;
  margin: 2px;
  border-radius: 8px;
  text-transform: capitalize;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: #efeafe !important;
}

.MuiDayCalendar-weekContainer > .Mui-selected {
  background-color: #5131d7 !important;
}

.MuiSelect-outlined > span {
  font-size: 14px !important;
  font-family: Inter, "sans-serif";
}

.MuiOutlinedInput-input {
  font-size: 14px !important;
  font-family: Inter, "sans-serif";
}

.MuiInputBase-input:focus-visible {
  border: none !important;
  outline: none;
}

.MuiPaper-root {
  box-shadow: none !important;
  border: 1px solid #eee;
}

.MuiAccordion-root {
  border: none !important;
}

.MuiAccordion-root:before {
  display: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

.custom-calendar > .fc .fc-button-primary:not(:disabled).fc-button-active {
  text-transform: capitalize;
  background-color: #532fd9 !important;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-color: #532fd9;
}

.custom-calendar > .fc .fc-button-primary {
  text-transform: capitalize;
  background-color: #efeafe !important;
  color: #532fd9;
  font-weight: 500;
  font-size: 14px;
  border-color: #532fd9;
}

.custom-calendar > .fc .fc-button-primary:hover {
  color: #532fd9;
}

.fc .fc-timegrid-axis-cushion {
  font-size: 14px;
  text-transform: capitalize;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 14px;
}

.fc .fc-toolbar-title {
  font-size: 18px;
  font-weight: bold;
}

.fc .fc-col-header-cell-cushion {
  font-size: 14px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  font-weight: bold;
  color: #532fd9;
}

.MuiChip-label {
  text-transform: capitalize;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

textarea {
  font-size: 14px;
}


#viewProperty .dataTable tr th {
  text-align: left !important;
}