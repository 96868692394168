:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

td {
  text-align: left !important;
}

table.dataTable td.dt-control:before {
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: inline-block;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  /* padding-right: 10px; */
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  right: 10%;
  opacity: 0.225;
  line-height: 6px;
  font-size: 0.4em;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  bottom: 57%;
  right: 1%;
  content: "▲";
  content: "▲"/"";
}

table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 42%;
  right: 1%;
  content: "▼";
  content: "▼"/"";
}

table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 0.6;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before {
  display: none;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

div.dataTables_scrollBody > table.dataTable > thead > tr > th:before,
div.dataTables_scrollBody > table.dataTable > thead > tr > th:after,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:before,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:after {
  display: none;
}

div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 2px;
}

div.dataTables_processing > div:last-child {
  position: relative;
  width: 80px;
  height: 15px;
  margin: 1em auto;
}

div.dataTables_processing > div:last-child > div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(13, 110, 253);
  background: rgb(var(--dt-row-selected));
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

div.dataTables_processing > div:last-child > div:nth-child(1) {
  left: 8px;
  animation: datatables-loader-1 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(2) {
  left: 8px;
  animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(3) {
  left: 32px;
  animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(4) {
  left: 56px;
  animation: datatables-loader-3 0.6s infinite;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: left;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

/* table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
} */
table.dataTable thead th,
table.dataTable thead td {
  padding-bottom: 10px;
  background: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2d3436;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 10px 6px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

/* table.dataTable tbody tr {
	background-color: transparent;
} */
table.dataTable tbody tr.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);
  color: rgb(255, 255, 255);
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable tbody tr.selected a {
  color: rgb(9, 10, 11);
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
  background-color: transparent;
  border-bottom: 1px solid #f2f2f2;
}

/* table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
} */
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none;
}

table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}

/*Rows of the body*/
table.dataTable.stripe > tbody > tr > *,
table.dataTable.display > tbody > tr > * {
  /* box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023); */
  background: transparent;
  padding: 14px 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2d3436;
}

table.dataTable.stripe > tbody > tr.odd.selected > *,
table.dataTable.display > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.923));
}

table.dataTable.hover > tbody > tr:hover > *,
table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
}

/* table.dataTable.hover > tbody > tr.selected:hover > *,
table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 1)) !important;
}
table.dataTable.order-column > tbody tr > .sorting_1,
table.dataTable.order-column > tbody tr > .sorting_2,
table.dataTable.order-column > tbody tr > .sorting_3,
table.dataTable.display > tbody tr > .sorting_1,
table.dataTable.display > tbody tr > .sorting_2,
table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
}
table.dataTable.order-column > tbody tr.selected > .sorting_1,
table.dataTable.order-column > tbody tr.selected > .sorting_2,
table.dataTable.order-column > tbody tr.selected > .sorting_3,
table.dataTable.display > tbody tr.selected > .sorting_1,
table.dataTable.display > tbody tr.selected > .sorting_2,
table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.919));
}
table.dataTable.display > tbody > tr.odd > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
}
table.dataTable.display > tbody > tr.odd > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
}
table.dataTable.display > tbody > tr.odd > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
}
table.dataTable.display > tbody > tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.954));
}
table.dataTable.display > tbody > tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.947));
}
table.dataTable.display > tbody > tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.939));
}
table.dataTable.display > tbody > tr.even > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
}
table.dataTable.display > tbody > tr.even > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
}
table.dataTable.display > tbody > tr.even > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
}
table.dataTable.display > tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.919));
}
table.dataTable.display > tbody > tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.911));
}
table.dataTable.display > tbody > tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.903));
}
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
}
table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
}
table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
}
table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.982));
}
table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.974));
}
table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.962));
} */
/* table.dataTable.no-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
} */
table.dataTable.align thead th,
table.dataTable.align thead td,
table.dataTable.align tfoot th,
table.dataTable.align tfoot td,
table.dataTable.align tbody th,
table.dataTable.align tbody td {
  text-align: left;
  /* padding-left: 20px; */
  /*
    min-width: 100px;
    */
}

table.dataTable.align thead th,
table.dataTable.align thead td,
table.dataTable.align tfoot th,
table.dataTable.align tfoot td,
table.dataTable.align tbody th,
table.dataTable.align tbody td {
  text-align: left;
  /* padding-left: 20px; */
  /* margin: 0px 10px */
  /* min-width: 100px; */
}

table.dataTable.align tbody td img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td,
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td,
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}

/* table.dataTable th{
    background-color: #d33333;
    display: relative;
} */
table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length {
  float: left;
  margin-left: 20px;
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #151515;
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  background-color: transparent;
  padding: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #151515;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
  margin-right: 28px;
}

.dataTables_wrapper .dataTables_filter label {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.dataTables_wrapper .dataTables_filter input {
  /* border: 1px solid #aaa;
  border-radius: 3px; */
  padding-left: 36px;
  padding-right: 6px;
  /* background-color: transparent; */
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 365px;
  height: 36px;
  background: url(../../assets/search.svg) no-repeat scroll 12px 9px
    rgb(243, 244, 246);

  /* Yoda Admin Template/Blacks/B-40 */
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  outline: none;
  font-size: 12px;
  font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: center;
  text-align: center;
  padding-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #767676 !important;
}

.dataTables_wrapper .dataTables_paginate {
  clear: both;
  float: right;
  text-align: right;
  padding-top: 0.8em;
  padding-right: 30px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1em;
  padding: 0.2rem 0.7rem;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #767676 !important;
  border: 1px solid transparent;
  border-radius: 2px;
  background: transparent;
  font-size: 12px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  border-radius: 5px;
  background-color: #532fd9;
  font-size: 12px;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(230, 230, 230, 0.1)),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #bfc3c7 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  font-size: 12px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border-radius: 5px;
  background-color: #efeafe;
  font-size: 12px;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%); */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #efeafe;
  font-size: 12px;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #2b2b2b),
    color-stop(100%, #0c0c0c)
  );
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111; */
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: inherit;
}

th.sorting_disabled::before,
th.sorting_disabled::after {
  display: none !important;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTablesWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > th,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > td,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > th,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > td {
  vertical-align: middle;
  font-size: 12px;
}

.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > th
  > div.dataTables_sizing,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > thead
  > tr
  > td
  > div.dataTables_sizing,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > th
  > div.dataTables_sizing,
.dataTables_wrapper
  .dataTables_scroll
  div.dataTables_scrollBody
  > table
  > tbody
  > tr
  > td
  > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead {
  background-color: #f5f5f7;
  padding-top: 10px;
}

.dataTables_wrapper .dataTable thead th {
  background-color: #f5f5f7;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: none;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th.dtr-control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact
  > tbody
  > tr
  > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed.compact
  > tbody
  > tr
  > th.dtr-control:before {
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control,
table.dataTable.dtr-column > tbody > tr > th.dtr-control,
table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-column > tbody > tr.parent td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}
th {
  padding-left: 0 !important;
}
table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

.dashboard-table .dataTables_scrollBody {
  height: 600px;
}

table.dataTable.display > tbody > tr.priority {
  background-color: #0010f726 !important;
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

table.dataTable td.dt-control:before,
table.dataTable tr.dt-hasChild td.dt-control:before {
  display: none;
  content: "";
}

.text-left {
  text-align: left !important;
  padding-left: 10px !important;
}
