

table.tenstackTable thead th {
    background-color: #f5f5f7;
    color: #2d3436;
    font-weight: bold;
}
table.tenstackTable thead th, table.tenstackTable thead td {
    padding-bottom: 10px;
    background: #f5f5f7;
    font-family: "Inter";
    font-weight: bold;
    font-size: 12px;
    color: #2d3436;
}

table.tenstackTable tbody td {
  font-size: 12px;
  font-family: "Inter";
}

.dataTables_paginate {
  font-size: 12px;
  font-family: "Inter";
}
.total_info {

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #767676 !important;
}